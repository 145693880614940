import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { resource } from '@util/resource';
import { catchError } from 'rxjs/operators';

export interface PreorderLink {
  id: string;
  wineryId: string;
  wineryChannelId: string;
  options: PreorderLinkOptions;
  state: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  stripeAccountId?: string;
}

export interface PreorderLinkOptions {
  language: string;
  isSendToActive: boolean;
  isOnlinePaymentActive: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PreorderLinkService {
  public linkId: string;

  get(id: string): Observable<PreorderLink> {
    return resource('preorders://link/id')
      .params({ id })
      .get<PreorderLink>()

  }
}
